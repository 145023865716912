import { useSphere } from '@react-three/cannon'
import { useFrame, useThree } from '@react-three/fiber'
import { useEffect, useRef } from 'react'
import { usePlayerControls } from '../../utils/controls'
import * as THREE from 'three'
import { skateboards, positionsVector } from '../../data/skate'
import { webPositions } from '../../data/webviews'

const BaseCharacter = ({
  onClosestSkateboardChanged,
  setShowWelcome,
  setMessage,
  ...props
}) => {
  const direction = new THREE.Vector3()
  const frontVector = new THREE.Vector3()
  const sideVector = new THREE.Vector3()
  const speed = new THREE.Vector3()
  const SPEED = 10

  const { camera } = useThree()

  const [ref, api] = useSphere(index => ({
    mass: 1,
    type: 'Dynamic',

    position: [0, 1, 0],
    ...props,
  }))

  const { forward, backward, left, right, jump } = usePlayerControls()
  const velocity = useRef([0, 0, 0])
  useEffect(() => api.velocity.subscribe(v => (velocity.current = v)), [])

  useFrame(state => {
    ref.current.getWorldPosition(camera.position)
    frontVector.set(0, 0, Number(backward) - Number(forward))
    sideVector.set(Number(left) - Number(right), 0, 0)
    direction
      .subVectors(frontVector, sideVector)
      .normalize()
      .multiplyScalar(SPEED)
      .applyEuler(camera.rotation)
    speed.fromArray(velocity.current)

    api.velocity.set(direction.x, velocity.current[1], direction.z)
    if (jump && Math.abs(velocity.current[1].toFixed(2)) < 0.05)
      api.velocity.set(velocity.current[0], 5, velocity.current[2])

    let closestDistance = Infinity
    let closestId = null
    skateboards.forEach((board, i) => {
      const distance = camera.position.distanceTo(positionsVector[i])
      if (distance < closestDistance) {
        closestDistance = distance
        closestId = board.id
      }
    })
    if (onClosestSkateboardChanged) {
      onClosestSkateboardChanged({
        id: closestId,
        distance: closestDistance,
      })
    }

    const webViewDistance = camera.position.distanceTo(webPositions[0].vector)
    const webViewDistance2 = camera.position.distanceTo(webPositions[1].vector)
    if (webViewDistance < 6) {
      setMessage(1)
      setShowWelcome(true)
      return
    }
    if (webViewDistance2 < 6) {
      setMessage(2)
      setShowWelcome(true)
    } else {
      setShowWelcome(false)
    }
  })

  return (
    <group>
      <mesh castShadow position={props.position} ref={ref}>
        <sphereGeometry args={props.args} />
        <meshStandardMaterial color='#FFFF00' />
      </mesh>
    </group>
  )
}

export default BaseCharacter
