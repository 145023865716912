import { Html } from '@react-three/drei'

const Message = ({ closestSkateboard }) => (
  <div
    className='message-view'
    // style={{
    //   position: 'absolute',
    //   bottom: '50%',
    //   left: '25%',
    //   transform: 'translateX(-25%) translateY(50%)',
    //   color: 'white',
    //   background: 'rgba(0, 0, 0, 0.5)',
    //   padding: '10px',
    //   borderRadius: '5px',
    //   textAlign: 'center',
    // }}
  >
    <p>Skateboard {closestSkateboard.id ? closestSkateboard.id : null}</p>
    <button onClick={() => alert('hello there')}>Test</button>
  </div>
)

export default Message
