import Frame from '../Frame'

const WebPane = ({ position }) => {
  const width = 5
  const height = width * (9 / 16)
  const size = [width, height, 1]
  const depth = 0.1
  const thickness = 0.2
  const color = 'black'
  return (
    <group
      position={position}
      // rotation={[0, Math.PI / 2, 0]}
    >
      <Frame
        width={width}
        height={height}
        depth={depth}
        thickness={thickness}
        color={color}
      />
      <mesh scale={size}>
        <planeGeometry />
        <meshBasicMaterial color='blue' />
      </mesh>
    </group>
  )
}

export default WebPane
