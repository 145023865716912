import { useState, useEffect } from 'react'
import { Canvas } from '@react-three/fiber'
import Experience from './components/Experience'
import Message from './components/Message'
import { io } from 'socket.io-client'
import { Loader } from '@react-three/drei'
import WindowView from './components/WindowView'

// "undefined" means the URL will be computed from the `window.location` object

// REACT THREE DOCS : https://docs.pmnd.rs/

const App = () => {
  const [showMessage, setShowMessage] = useState(false)
  const [message, setMessage] = useState(false)
  const [isLoading, setisLoading] = useState(false)
  const [showWelcome, setShowWelcome] = useState(false)
  const [closestSkateboard, setClosestSkateboard] = useState({
    id: null,
    distance: Infinity,
  })
  // const [socket, setSocket] = useState(null)

  // useEffect(() => {
  //   const newSocket = io(`http://localhost:6969`)
  //   console.log(newSocket)
  //   setSocket(newSocket)

  //   // Listen for chat messages
  //   newSocket.on('chat message', function (msg) {
  //     console.log(msg)
  //   })
  //   return () => newSocket.close()
  // }, [setSocket])
  return (
    <>
      <div className='webview'>
        {isLoading && (
          <div className='loading-container'>
            <h1>loading</h1>
          </div>
        )}
        {showMessage && (
          <Message
            showMessage={showMessage}
            closestSkateboard={closestSkateboard}
          />
        )}
        {showWelcome && <WindowView message={message} />}
      </div>
      <Canvas>
        <Experience
          setMessage={setMessage}
          showMessage={showMessage}
          setShowMessage={setShowMessage}
          showWelcome={showWelcome}
          setShowWelcome={setShowWelcome}
          closestSkateboard={closestSkateboard}
          setClosestSkateboard={setClosestSkateboard}
        />
      </Canvas>
      <Loader />
    </>
  )
}

export default App
