import { Suspense } from 'react'
import { useAspect, useVideoTexture, useTexture } from '@react-three/drei'
import content from './mp001.mp4'
import imgg from './pp.png'
import Frame from '../Frame'

export default function VideoPane() {
  const width = 14
  const height = width * (9 / 16)
  const size = [width, height, 1]
  const depth = 0.1
  const thickness = 0.2
  const color = 'black'
  return (
    <group
      position={[-19.9984, 5.999887943267822, 3.232421636581421]}
      rotation={[0, Math.PI / 2, 0]}
    >
      <Frame
        width={width}
        height={height}
        depth={depth}
        thickness={thickness}
        color={color}
      />
      <mesh scale={size}>
        <planeGeometry />
        {/* <Suspense fallback={<FallbackMaterial url={imgg} />}> */}
        <VideoMaterial url='/mp001.mp4' />
        {/* </Suspense> */}
      </mesh>
    </group>
  )
}

function VideoMaterial({ url }) {
  const texture = useVideoTexture(url)
  return <meshBasicMaterial map={texture} toneMapped={false} />
}

function FallbackMaterial({ url }) {
  const texture = useTexture(url)
  return <meshBasicMaterial map={texture} toneMapped={false} />
}
