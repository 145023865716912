import React from 'react'

const Frame = ({ width, height, depth, thickness, color }) => {
  return (
    <group>
      {/* LEFT */}
      <mesh position={[-width / 2, 0, 0]}>
        <boxGeometry args={[thickness, height + 1 * thickness, depth]} />
        <meshStandardMaterial color={color} />
      </mesh>
      {/* RIGHT */}
      <mesh position={[width / 2, 0, 0]}>
        <boxGeometry args={[thickness, height + 1 * thickness, depth]} />
        <meshStandardMaterial color={color} />
      </mesh>
      {/* BOTTOM */}
      <mesh position={[0, -height / 2, 0]}>
        <boxGeometry args={[width, thickness, depth]} />
        <meshStandardMaterial color={color} />
      </mesh>
      {/* TOP */}
      <mesh position={[0, height / 2, 0]}>
        <boxGeometry args={[width, thickness, depth]} />
        <meshStandardMaterial color={color} />
      </mesh>
    </group>
  )
}

export default Frame
