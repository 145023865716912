import * as THREE from 'three'

export const webPositions = [
  {
    key: '001',
    numbers: [0.1, 5, -19.9],
    vector: new THREE.Vector3(0.1, 5, -19.9),
  },
  {
    key: '002',
    numbers: [45.1, 5, -19.9],
    vector: new THREE.Vector3(45.1, 5, -19.9),
  },
]
