import { useEffect, useRef, useState, Suspense } from 'react'
import { useFrame, useThree, extend } from '@react-three/fiber'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { FirstPersonControls } from '@react-three/drei'
import { skateboards, positions } from '../../data/skate'
import { useGLTF } from '@react-three/drei'
import Skate from '../Skate/Skate'
import { Loader, PointerLockControls } from '@react-three/drei'
import { useControls } from 'leva'
import { Physics } from '@react-three/cannon'
import BaseCharacter from '../BaseCharacter'
import Floor from '../Floor'
import Walls from '../Walls'
import VideoPane from '../VideoPane'
import WebPane from '../WebPane'
import { webPositions } from '../../data/webviews'
extend({ OrbitControls })

const Experience = ({
  showMessage,
  setShowMessage,
  closestSkateboard,
  setClosestSkateboard,
  showWelcome,
  setShowWelcome,
  setMessage,
}) => {
  const { camera } = useThree()

  useEffect(() => {
    camera.rotation.y = -Math.PI / 2
  }, [])

  useEffect(() => {
    if (closestSkateboard.distance < 4.3) {
      setShowMessage(true)
    } else {
      setShowMessage(false)
    }
  }, [closestSkateboard])

  // BELOW IS THE CONTROLS FOR THE IN BROWSER EDITOR

  // const { position } = useControls({
  //   position: {
  //     value: { x: -2, y: 0, z: 0 },
  //     step: 0.01,
  //   },
  // })
  // useGLTF.preload(skateboards.map(skateboard => skateboard.glb))

  return (
    <>
      {/* <orbitControls args={[camera, gl.domElement]} /> */}
      <Suspense fallback={null}>
        <PointerLockControls />
        <pointLight position={[10, 10, 10]} />
        <ambientLight intensity={0.25} />
        {/* <mesh ref={yo}>
        <sphereGeometry args={[1.5, 32, 32]} />
        <meshBasicMaterial color='hotpink' wireframe />
      </mesh> */}
        <Physics gravity={[0, -9.8, 0]}>
          <BaseCharacter
            setMessage={setMessage}
            controls
            position={[-6.6, 4, 5]}
            args={[4]}
            color='yellow'
            onClosestSkateboardChanged={setClosestSkateboard}
            setShowWelcome={setShowWelcome}
          />
          {skateboards.map((board, i) => {
            return (
              <Skate
                key={board.id}
                id={board.glb}
                position={positions[i]}
                showMessage={showMessage}
                closestSkateboard={closestSkateboard}
              />
            )
          })}
          <Walls />
          <Floor rotation={[Math.PI / -2, 0, 0]} color='purple' />
        </Physics>
        <VideoPane />
        {webPositions.map(w => (
          <WebPane key={w.key} position={w.numbers} />
        ))}
      </Suspense>
    </>
  )
}

export default Experience
