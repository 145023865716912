import { usePlane } from '@react-three/cannon'
import { useTexture } from '@react-three/drei'
import * as THREE from 'three'

const Floor = props => {
  const [ref] = usePlane(index => ({ type: 'Static', mass: 0, ...props }))

  const texture = useTexture('/floor.jpeg')
  texture.repeat.set(200, 200)
  texture.wrapS = texture.wrapT = THREE.RepeatWrapping
  return (
    <mesh receiveShadow rotation={props.rotation} ref={ref}>
      <planeGeometry args={[1000, 1000]} />
      <meshStandardMaterial map={texture} displacementScale={0.2} />
    </mesh>
  )
}

export default Floor
