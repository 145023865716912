import * as THREE from 'three'

export const skateboards = [
  {
    id: '0001',
    glb: '0001.glb',
  },
  {
    id: '0002',
    glb: '0002.glb',
  },
  // {
  //   id: '0003',
  //   glb: '0003.glb',
  // },
  // {
  //   id: '0004',
  //   glb: '0004.glb',
  // },
  // {
  //   id: '0005',
  //   glb: '0005.glb',
  // },
  {
    id: '0006',
    glb: '0006.glb',
  },
  // {
  //   id: '0007',
  //   glb: '0007.glb',
  // },
  // {
  //   id: '0008',
  //   glb: '0008.glb',
  // },
  // {
  //   id: '0009',
  //   glb: '0009.glb',
  // },
  // {
  //   id: '0010',
  //   glb: '0010.glb',
  // },
  // {
  //   id: '0011',
  //   glb: '0011.glb',
  // },
  // {
  //   id: '0012',
  //   glb: '0012.glb',
  // },
  // {
  //   id: '0013',
  //   glb: '0013.glb',
  // },
  // {
  //   id: '0014',
  //   glb: '0014.glb',
  // },
  // {
  //   id: '0015',
  //   glb: '0015.glb',
  // },
  // {
  //   id: '0016',
  //   glb: '0016.glb',
  // },
  // {
  //   id: '0017',
  //   glb: '0017.glb',
  // },
  // {
  //   id: '0018',
  //   glb: '0018.glb',
  // },
  // {
  //   id: '0019',
  //   glb: '0019.glb',
  // },
  // {
  //   id: '0020',
  //   glb: '0020.glb',
  // },
]

export const positions = [
  [0, 4, 0],
  [8, 4, 0],
  [16, 4, 0],
  // [24, 4, 0],
  // [32, 4, 0],
  // [40, 4, 0],
  // [48, 4, 0],
  // [56, 4, 0],
  // [64, 4, 0],
  // [72, 4, 0],
  // [0, 4, 10],
  // [8, 4, 10],
  // [16, 4, 10],
  // [24, 4, 10],
  // [32, 4, 10],
  // [40, 4, 10],
  // [48, 4, 10],
  // [56, 4, 10],
  // [64, 4, 10],
  // [72, 4, 10],
]

export const positionsVector = [
  new THREE.Vector3(positions[0][0], positions[0][1], positions[0][2]),
  new THREE.Vector3(positions[1][0], positions[1][1], positions[1][2]),
  new THREE.Vector3(positions[2][0], positions[2][1], positions[2][2]),
  // new THREE.Vector3(positions[3][0], positions[3][1], positions[3][2]),
  // new THREE.Vector3(positions[4][0], positions[4][1], positions[4][2]),
  // new THREE.Vector3(positions[5][0], positions[5][1], positions[5][2]),
  // new THREE.Vector3(positions[6][0], positions[6][1], positions[6][2]),
  // new THREE.Vector3(positions[7][0], positions[7][1], positions[7][2]),
  // new THREE.Vector3(positions[8][0], positions[8][1], positions[8][2]),
  // new THREE.Vector3(positions[9][0], positions[9][1], positions[9][2]),
  // new THREE.Vector3(positions[10][0], positions[10][1], positions[10][2]),
  // new THREE.Vector3(positions[11][0], positions[11][1], positions[11][2]),
  // new THREE.Vector3(positions[12][0], positions[12][1], positions[12][2]),
  // new THREE.Vector3(positions[13][0], positions[13][1], positions[13][2]),
  // new THREE.Vector3(positions[14][0], positions[14][1], positions[14][2]),
  // new THREE.Vector3(positions[15][0], positions[15][1], positions[15][2]),
  // new THREE.Vector3(positions[16][0], positions[16][1], positions[16][2]),
  // new THREE.Vector3(positions[17][0], positions[17][1], positions[17][2]),
  // new THREE.Vector3(positions[18][0], positions[18][1], positions[18][2]),
  // new THREE.Vector3(positions[19][0], positions[19][1], positions[19][2]),
]
