import React from 'react'
import { useBox } from '@react-three/cannon'

const Wall = ({ position, rotation, args, color }) => {
  const [ref] = useBox(() => ({
    type: 'Static',
    position,
    rotation,
    args,
  }))
  return (
    <mesh ref={ref} position={position} rotation={rotation}>
      <boxGeometry args={args} />
      <meshLambertMaterial color={color} />
    </mesh>
  )
}

const Walls = () => {
  return (
    <>
      <Wall position={[0, 10, -20]} args={[182, 20, 0.001]} color='white' />
      <Wall position={[0, 10, 30]} args={[182, 20, 10]} color='white' />
      <Wall
        position={[-20, 10, 0]}
        rotation={[0, Math.PI / 2, 0]}
        args={[50, 20, 0.001]}
        color='white'
      />
      <Wall
        position={[90, 10, 0]}
        rotation={[0, Math.PI / 2, 0]}
        args={[50, 20, 0.001]}
        color='white'
      />
      <Wall
        position={[0, 12, 0]}
        rotation={[Math.PI / 2, 0, 0]}
        args={[182, 50, 0.001]}
        color='white'
      />
    </>
  )
}

export default Walls
