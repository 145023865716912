export const user = {
  name: 'adz',
  description: 'Just seeing what I can build out here ✌️',
  socials: {
    instagram: 'adzbot',
    github: 'adam-botma',
    twitter: '_adz_eth_',
  },
  buttons: [
    { text: 'View my Portfolio', link: 'https://www.adambotma.com' },
    { text: 'See my Portfolio in 3d', link: 'https://3d.adz.dev' },
    { text: 'email me', link: 'mailto:adam.botma@me.com' },
  ],
}
