import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { useFrame } from '@react-three/fiber'

export default function Skate({
  id,
  position,
  showMessage,
  closestSkateboard,
}) {
  const { nodes, materials } = useGLTF(`/${id}`)

  if (!nodes || !materials) {
    return (
      <mesh>
        <boxBufferGeometry args={[1, 1, 1]} />
        <meshBasicMaterial color='red' />
      </mesh>
    )
  }

  const yo = useRef()

  useFrame(({ clock }) => {
    yo.current.rotation.z = clock.getElapsedTime() / 1.5 // yo is the reference to the mesh we are animating
  })
  return (
    <>
      <group dispose={null}>
        <group name='Scene'>
          <group
            ref={yo}
            name='SANTA_SKATE'
            position={position}
            rotation={[Math.PI / 2, 0, Math.PI]} // animation will rotate on the z axis
            scale={0.3}
          >
            <mesh
              name='Mesh012'
              geometry={nodes.Mesh012.geometry}
              material={materials.SkateBoardSub}
            />
            <mesh
              name='Mesh012_1'
              geometry={nodes.Mesh012_1.geometry}
              material={materials.METAL}
            />
            <mesh
              name='Mesh012_2'
              geometry={nodes.Mesh012_2.geometry}
              material={materials.GRIP}
            />
            <mesh
              name='BOTTOM_DESIGN'
              geometry={nodes.BOTTOM_DESIGN.geometry}
              material={materials.Material}
              position={[0, 0.32, 0.41]}
            />
            <mesh
              name='Wheel01'
              geometry={nodes.Wheel01.geometry}
              material={materials.WHEELS}
              position={[0.3, -4.14, 7.54]}
            />
            <mesh
              name='Wheel02'
              geometry={nodes.Wheel02.geometry}
              material={materials.WHEELS}
              position={[0.3, -4.14, 7.54]}
            />
            <mesh
              name='Wheel03'
              geometry={nodes.Wheel03.geometry}
              material={materials.WHEELS}
              position={[1.36, -0.5, -3.93]}
            />
            <mesh
              name='Wheel04'
              geometry={nodes.Wheel04.geometry}
              material={materials.WHEELS}
              position={[0.3, -4.14, 7.54]}
            />
          </group>
        </group>
      </group>
    </>
  )
}
