import './index.scss'
import { user } from '../../data/user'

const WindowView = ({ message }) => {
  return (
    <div className='message-view'>
      {message === 1 && (
        <div className='link-panel'>
          <div className='header'>
            <h2>{user.name}</h2>
            <p>{user.description}</p>
          </div>
          <div className='links'>
            {user.buttons.map(l => (
              <a className='button' href={l.link}>
                {l.text}
              </a>
            ))}
          </div>
        </div>
      )}
      {message === 2 && <h1>guest book</h1>}
    </div>
  )
}

export default WindowView
